import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import enTranslation from '@src/locales/en/translation.json'
import zhTwTranslation from '@src/locales/zh-tw/translation.json'
import backend from "i18next-xhr-backend"
import envConfig from '@src/configs/envConfig'
import { getLanguage } from '@src/utils/commonUtil'

const i18nURL = envConfig.apiHost + envConfig.basePath + envConfig.i18nPath + '{{lng}}'

i18n
  .use(backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    react: {
      useSuspense: false //   <---- this will do the magic
    },
    backend: {
      loadPath: i18nURL,
      // allowMultiLoading: false,
      // crossDomain: true
    },
    // we init with resources
    // resources: {
    //   en: {
    //     translations: enTranslation
    //   },
    //   tc: {
    //     translations: zhTwTranslation
    //   }
    // },
    fallbackLng: 'en',
    lng: 'sc',
    debug: false,
    // have a common namespace used around the full app
    // ns: ['aboutUs', 'member'],
    // defaultNS: 'member',
    // keySeparator: true, // we use content as keys
    interpolation: {
      escapeValue: false
    }
  })

export default i18n
