import React from 'react'
import '@src/services/i18n'
// import { MuiThemeProvider } from '@material-ui/core/styles'
// import Layout from '@src/components/layouts/layout'
// import RootStore from '@board-web/stores/RootStore'

// import RootStore from '@bill-web/stores/RootStore'
// import Layout from '@bill-web/components/layouts/layout'
// import billTheme from '@bill-web/styles/themes/billTheme'

// import RootStore from '@hkpca-web/stores/RootStore'
// import Layout from '@hkpca-web/components/layouts/layout'

// import RootStore from '@ef-web/stores/RootStore'
// import Layout from '@ef-web/components/layouts/layout'



// const rootStore = new RootStore()

export default ({ element }) => (
  <>
    {element}
  </>
)
