import element from './wrap-root-element'
import '@src/styles/global.scss'
import '@src/styles/flex.scss'

// import '@board-web/styles/home.scss'
// import '@bill-web/styles/home.scss'
// import '@bill-web/styles/fonts/flaticon.css'

export const wrapRootElement = element

