// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-about-hkpca-js": () => import("./../../../src/pages/about-us/about-hkpca.js" /* webpackChunkName: "component---src-pages-about-us-about-hkpca-js" */),
  "component---src-pages-about-us-article-of-association-js": () => import("./../../../src/pages/about-us/article-of-association.js" /* webpackChunkName: "component---src-pages-about-us-article-of-association-js" */),
  "component---src-pages-about-us-executive-committee-js": () => import("./../../../src/pages/about-us/executive-committee.js" /* webpackChunkName: "component---src-pages-about-us-executive-committee-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-about-us-message-from-the-president-js": () => import("./../../../src/pages/about-us/message-from-the-president.js" /* webpackChunkName: "component---src-pages-about-us-message-from-the-president-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-custom-list-js": () => import("./../../../src/pages/custom-list.js" /* webpackChunkName: "component---src-pages-custom-list-js" */),
  "component---src-pages-custom-page-js": () => import("./../../../src/pages/custom-page.js" /* webpackChunkName: "component---src-pages-custom-page-js" */),
  "component---src-pages-custom-post-js": () => import("./../../../src/pages/custom-post.js" /* webpackChunkName: "component---src-pages-custom-post-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-ebook-js": () => import("./../../../src/pages/ebook.js" /* webpackChunkName: "component---src-pages-ebook-js" */),
  "component---src-pages-event-detail-js": () => import("./../../../src/pages/event/detail.js" /* webpackChunkName: "component---src-pages-event-detail-js" */),
  "component---src-pages-event-exhibition-js": () => import("./../../../src/pages/event/exhibition.js" /* webpackChunkName: "component---src-pages-event-exhibition-js" */),
  "component---src-pages-event-index-js": () => import("./../../../src/pages/event/index.js" /* webpackChunkName: "component---src-pages-event-index-js" */),
  "component---src-pages-event-other-activities-js": () => import("./../../../src/pages/event/other-activities.js" /* webpackChunkName: "component---src-pages-event-other-activities-js" */),
  "component---src-pages-event-seminar-training-js": () => import("./../../../src/pages/event/seminar-training.js" /* webpackChunkName: "component---src-pages-event-seminar-training-js" */),
  "component---src-pages-exhibition-detail-js": () => import("./../../../src/pages/exhibition/detail.js" /* webpackChunkName: "component---src-pages-exhibition-detail-js" */),
  "component---src-pages-exhibition-iec-exhibition-js": () => import("./../../../src/pages/exhibition/iec-exhibition.js" /* webpackChunkName: "component---src-pages-exhibition-iec-exhibition-js" */),
  "component---src-pages-exhibition-index-js": () => import("./../../../src/pages/exhibition/index.js" /* webpackChunkName: "component---src-pages-exhibition-index-js" */),
  "component---src-pages-exhibition-other-exhibition-js": () => import("./../../../src/pages/exhibition/other-exhibition.js" /* webpackChunkName: "component---src-pages-exhibition-other-exhibition-js" */),
  "component---src-pages-form-download-detail-js": () => import("./../../../src/pages/form-download-detail.js" /* webpackChunkName: "component---src-pages-form-download-detail-js" */),
  "component---src-pages-form-download-js": () => import("./../../../src/pages/form-download.js" /* webpackChunkName: "component---src-pages-form-download-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-job-opportunities-js": () => import("./../../../src/pages/job-opportunities.js" /* webpackChunkName: "component---src-pages-job-opportunities-js" */),
  "component---src-pages-job-opportunity-detail-js": () => import("./../../../src/pages/job-opportunity-detail.js" /* webpackChunkName: "component---src-pages-job-opportunity-detail-js" */),
  "component---src-pages-journal-detail-js": () => import("./../../../src/pages/journal-detail.js" /* webpackChunkName: "component---src-pages-journal-detail-js" */),
  "component---src-pages-journal-js": () => import("./../../../src/pages/journal.js" /* webpackChunkName: "component---src-pages-journal-js" */),
  "component---src-pages-link-exchange-js": () => import("./../../../src/pages/link-exchange.js" /* webpackChunkName: "component---src-pages-link-exchange-js" */),
  "component---src-pages-market-information-detail-js": () => import("./../../../src/pages/market-information/detail.js" /* webpackChunkName: "component---src-pages-market-information-detail-js" */),
  "component---src-pages-market-information-ep-policies-js": () => import("./../../../src/pages/market-information/ep-policies.js" /* webpackChunkName: "component---src-pages-market-information-ep-policies-js" */),
  "component---src-pages-market-information-index-js": () => import("./../../../src/pages/market-information/index.js" /* webpackChunkName: "component---src-pages-market-information-index-js" */),
  "component---src-pages-market-information-market-news-js": () => import("./../../../src/pages/market-information/market-news.js" /* webpackChunkName: "component---src-pages-market-information-market-news-js" */),
  "component---src-pages-market-information-marketing-research-js": () => import("./../../../src/pages/market-information/marketing-research.js" /* webpackChunkName: "component---src-pages-market-information-marketing-research-js" */),
  "component---src-pages-market-information-technical-article-js": () => import("./../../../src/pages/market-information/technical-article.js" /* webpackChunkName: "component---src-pages-market-information-technical-article-js" */),
  "component---src-pages-member-index-js": () => import("./../../../src/pages/member/index.js" /* webpackChunkName: "component---src-pages-member-index-js" */),
  "component---src-pages-member-join-event-js": () => import("./../../../src/pages/member/join-event.js" /* webpackChunkName: "component---src-pages-member-join-event-js" */),
  "component---src-pages-member-join-us-js": () => import("./../../../src/pages/member/join-us.js" /* webpackChunkName: "component---src-pages-member-join-us-js" */),
  "component---src-pages-member-member-list-js": () => import("./../../../src/pages/member/member-list.js" /* webpackChunkName: "component---src-pages-member-member-list-js" */),
  "component---src-pages-member-member-news-detail-js": () => import("./../../../src/pages/member/member-news/detail.js" /* webpackChunkName: "component---src-pages-member-member-news-detail-js" */),
  "component---src-pages-member-member-news-list-js": () => import("./../../../src/pages/member/member-news/list.js" /* webpackChunkName: "component---src-pages-member-member-news-list-js" */),
  "component---src-pages-news-detail-js": () => import("./../../../src/pages/news/detail.js" /* webpackChunkName: "component---src-pages-news-detail-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-partner-js": () => import("./../../../src/pages/partner.js" /* webpackChunkName: "component---src-pages-partner-js" */),
  "component---src-pages-photo-js": () => import("./../../../src/pages/photo.js" /* webpackChunkName: "component---src-pages-photo-js" */),
  "component---src-pages-post-detail-js": () => import("./../../../src/pages/post-detail.js" /* webpackChunkName: "component---src-pages-post-detail-js" */),
  "component---src-pages-search-result-js": () => import("./../../../src/pages/search-result.js" /* webpackChunkName: "component---src-pages-search-result-js" */),
  "component---src-pages-survey-detail-js": () => import("./../../../src/pages/survey-detail.js" /* webpackChunkName: "component---src-pages-survey-detail-js" */),
  "component---src-pages-survey-js": () => import("./../../../src/pages/survey.js" /* webpackChunkName: "component---src-pages-survey-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */)
}

